* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "LuckiestGuy";
  src: local("LuckiestGuy"),
   url("./fonts/LuckiestGuy-Regular.ttf") format("truetype");
}

html {
  width: 100vw;
  overflow-x: hidden;
}

body {
  scrollbar-width: none;  /* Firefox */
}
::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

/* This is to modify the input placeholder on mobile to fit everything on one line */
@media only screen and (max-width: 600px) {
  input::-webkit-input-placeholder {
    font-size: 0.81em !important;
  }
}

#Content-Container {
  display: flex;
  flex-direction: row;
  flex-grow: 7;
}

.error {
  color: red;
}


#Auth-Container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.scrollbar {
  overflow-y: scroll;
}

.style-4::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #00000000;
  opacity: 0;
  height: 20px;
}

.style-4::-webkit-scrollbar
{
  width: 5px;
  background-color: #00000000;

}

.style-4::-webkit-scrollbar-thumb
{
  background-color: #00000031;
  border: 2px solid #00000000;
}

.MuiDataGrid-toolbar .MuiBadge-badge {
  z-index: 0 !important;
}