#Notification-Container {
    background-color: lightgreen;
    margin: 1rem 0rem;
    display: flex;
    justify-content: space-between;
}

.Button-Cancel {
    /* height: 1rem;
    width: 1rem;
    padding: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.GI-Cancel {
    height: 2rem;
    width: 2rem;
    padding: 0;
}